import React from "react";
import {ArrowNarrowLeft} from "tabler-icons-react";

const Pricing = () => {

    return (

        <div className={"container pricing"}>
            <a className={"back"} href={"/"}>
                <ArrowNarrowLeft size={50} alt={"arrow"} color={"black"}/>
            </a>
            <div className={"empty"}/>
            <ul className={"list"}>
                <li className={"item"}>
                    <span className={"bold medium"}>Cennik</span>
                </li>
                <li className={"item"}>
                    <span className={"regular medium"}>Pierwsza wizyta (60-90 min.) – </span>
                    <span className={"bold medium"}>210 zł</span>
                </li>
                <li className={"item"}>
                    <span className={"regular medium"}>Kolejna wizyta (30-45 min.) – </span>
                    <span className={"bold medium"}>180 zł</span>
                </li>
                <li className={"item"}>
                    <span className={"regular medium"}>Wystawienie recepty (dla stałych pacjentów z ustabilizowanym leczeniem) – </span>
                    <span className={"bold medium"}>50 zł</span>
                </li>
                <li className={"item"}>
                    <span className={"regular medium"}>Wystawienie zaświadczenia lekarskiego – </span>
                    <span className={"bold medium"}>100 zł</span>
                </li>
                <li className={"item"}>
                        <span className={"regular medium"}>
                            Płatność za wizytę można zrealizować gotówką, kartką kredytową lub płatniczą, blikiem lub przelewem bankowym.
                        </span>
                </li>
                <li className={"item"}>
                    <span className={"regular medium"}>Konto bankowe: </span>
                    <span className={"bold medium"}>PL26 1140 2004 0000 3802 8290 8733</span>
                </li>
            </ul>
        </div>

    )
}

export default Pricing;