import React from "react";

const Dropdown = ({ submenus, anchorClass, open }) => {

    return (
        <ul className={"dropdown " + (open ? "active" : "")}>
            {submenus.map((submenu, index) => (
                <li key={index} className="dropdown-item">
                    <a className={anchorClass} href={submenu.url}>{submenu.title}</a>
                </li>
            ))}
        </ul>
    );
};

export default Dropdown;

