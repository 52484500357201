import React, {useRef} from "react";

import Header from "../../partial/header";
import Hero from "./hero";
import About from "./about";
import Services from "./services";
import Contact from "./contact";
import Footer from "../../partial/footer";
import "../../../index.css";

const Home = () => {
    const servicesRef = useRef(null);
    const contactRef = useRef(null);
    const aboutRef = useRef(null);

    const scrollTo = (elementRef) => {
        if(elementRef && elementRef.current){
            window.scrollTo({
                top: elementRef.current.offsetTop,
                behavior: "smooth"
            });
        }
    }

    return (

        <div className={"main"}>
            <Header scrollTo={scrollTo} servicesRef={servicesRef} contactRef={contactRef}/>
            <section className={"section"}>
                <Hero aboutRef={aboutRef} scrollTo={scrollTo}/>
            </section>
            <section className={"section"} ref={aboutRef}>
                <About aboutRef={aboutRef}/>
            </section>
            <section className={"section"} ref={servicesRef}>
                <Services/>
            </section>
            <section className={"section"} ref={contactRef}>
                <Contact/>
            </section>
            <Footer scrollTo={scrollTo} servicesRef={servicesRef} contactRef={contactRef}/>
        </div>

    )
}

export default Home;