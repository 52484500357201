import React, {useState} from "react";
import "../../index.css";
import MenuItem from "./menuItem";

const Menu = ( {scrollTo, contactRef, servicesRef, footer} ) => {
    const items = [{
        title: 'o mnie',
        url: '/o-mnie',
        submenu: [{
            title: 'prywatnie', url: '/o-mnie',
        }, {
            title: 'zawodowo', url: '/o-mnie-zawodowo',
        }]
    }, {
        title: 'zakres pomocy', scroll: servicesRef
    }, {
        title: 'cennik', url: '/cennik',
    }, {
        title: 'kontakt', scroll: contactRef
    }];

    const anchorClass = (footer ? "small" : "medium") + " regular black no-underline";
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    return (

        <nav className={"navbar"}>
            <ul className={"menu-list " + (menuOpen ? "active" : "")}>
                {items.map((item, index) => {
                    return <MenuItem item={item} key={index} anchorClass={anchorClass} scrollTo={scrollTo}/>;
                })}
            </ul>
            {!footer && (

                <div id="menu-btn" onClick={toggleMenu} className={menuOpen ? "open" : ""}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

            )}
        </nav>

    );
};

export default Menu;