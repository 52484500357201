import React from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import Home from "./components/pages/home/home";
import Pricing from "./components/pages/pricing/pricing";
import About from "./components/pages/about/about";
import More from "./components/pages/about/more";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "/o-mnie",
        element: <About/>
    },
    {
        path: "/o-mnie-zawodowo",
        element: <More/>
    },
    {
        path: "/cennik",
        element: <Pricing/>
    }
]);

const App = () => {
    return (
        <RouterProvider router={router}/>
    );
}

export default App;
