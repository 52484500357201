import React from "react";
import {ArrowNarrowLeft} from "tabler-icons-react";

const About = () => {
    return (

        <div className={"container main-about"}>
            <a className={"back"} href={"/"}>
                <ArrowNarrowLeft size={50}/>
            </a>
            <ul className={"list list-style-none"}>
                <li className={"item bold medium"}>Wykształcenie zawodowe:</li>
                <li className={"item regular medium"}>
                    Szkolenie psychoterapeutyczne w nurcie humanistycznym w Szkole Psychoterapii w Ośrodku INTRA<br/>
                    2021 – obecnie
                </li>
                <li className={"item regular medium"}>
                    Szpital Bielański im. ks. Jerzego Popiełuszki – rezydentura z psychiatrii<br/>
                    2020 – obecnie
                </li>
                <li className={"item regular medium"}>
                    Szpital Bielański im. ks. Jerzego Popiełuszki – staż podyplomowy<br/>
                    2019 – 2020
                </li>
                <li className={"item regular medium"}>
                    Warszawski Uniwersytet Medyczny, Kierunek Lekarski<br/>
                    2013 – 2019
                </li>
            </ul>
            <ul className={"list list-style-none"}>
                <li className={"item bold medium"}>Działalność naukowa:</li>
                <li className={"item regular medium"}>
                    Ukończenie szkolenia pt. „Analizy danych i statystyka medyczna”
                    BIO-STAT Studio Badań i Analiz Biostatystycznych, 2019
                </li>
                <li className={"item regular medium"}>
                    Nagroda za prezentacje w sesji plakatowej, XVII Podlaskie Warsztaty Psychiatryczne, Białowieża
                    2019<br/>
                    Plakat pt.: “What do medical students think about medical doctors struggling with mental health
                    issues?”
                </li>
                <li className={"item regular medium"}>
                    II miejsce w sesji plakatowej, XI Międzynarodowy Kongres Naukowy-Szkoleniowy Psychiatria
                    ambulatoryjna, Wisła 2017<br/>
                    Plakat pt.: “Obsessive-compulsive tendencies among medical students in Poland“
                </li>
                <li className={"item regular medium"}>
                    Studenckie Koło Naukowe Psychiatrii Ambulatoryjnej przy Centrum Terapii DIALOG<br/>
                    2017 – 2019
                </li>
                <li className={"item regular medium"}>
                    Studenckie Koło Naukowe Psychiatrii WUM przy Katedrze i Klinice Psychiatrycznej WUM (w Szpitalu
                    Nowowiejskim)<br/>
                    2014 – 2019
                </li>
                <li className={"item regular medium"}>
                    Studenckie Koło Naukowe Psychiatryczne WUM im. prof. Jana Mazurkiewicza<br/>
                    2016 – 2018
                </li>
                <li className={"item regular medium"}>
                    Organizacja II Ogólnopolskiej Konferencji Studenckich Kół Naukowych Psychiatrii oraz Psychiatrii
                    Dzieci i Młodzieży „Po drugiej stronie lustra”<br/>
                    2017
                </li>
                <li className={"item regular medium"}>
                    Dwukrotny staż o charakterze shadowing na oddziale Psychiatrii w Szpitalu Uniwersyteckim Alabamy,
                    Birmingham USA (UAB Hospital, Alabama)<br/>
                    2013, 2015
                </li>
                <li className={"item regular medium"}>
                    Udział w konferencjach m.in: <br/>Psychiatria – Kontrowersje w psychiatrii, Kraków 2016, 2017,
                    2019<br/>
                    Schizofrenia forum, Warszawa 2017, 2019
                </li>
            </ul>
        </div>

    )
}

export default About;