import {useEffect, useRef, useState} from "react";

const useElementOnScreen = (options) => {

    const currentRef = useRef(null)
    const [ isVisible, setIsVisible ] = useState(false);

    const animate = (entries) => {
        const [entry] = entries;
        setIsVisible(entry.isIntersecting)
    }

    useEffect(() => {

        const observer = new IntersectionObserver(animate, options);
        if (currentRef.current) observer.observe(currentRef.current);

        return () => {
            if (currentRef.current) observer.observe(currentRef.current);
        }
    }, [currentRef, options]);
    return [isVisible, currentRef];
}

export default useElementOnScreen;
