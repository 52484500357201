import React from 'react';
import styled from "styled-components";
import {ArrowNarrowUp, Copyright} from 'tabler-icons-react';
import Menu from "./menu";

const Footer = ({scrollTo, contactRef, servicesRef, footer}) => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0, behavior: "smooth"
        });
    };

    return (

        <div className={"footer"}>
            <div className={"rights"}>
                <Copyright/>
                <span className={"bold small"}>Gabinet Zuzanna Goetz-Kundera</span>
            </div>
            <div className={"line"}/>
            <div className={"number"}>
                <span className={"regular small"}>Tel.&nbsp;</span>
                <span className={"bold small"}>789 236 385</span>
            </div>
            <div className={"line"}/>
            <Menu footer={true} scrollTo={scrollTo} servicesRef={servicesRef} contactRef={contactRef}/>
            <ArrowNarrowUp size={50} className={"arrow up"} alt={"arrow_top"} onClick={scrollToTop}/>
        </div>

    );
}

export default Footer;
