import React, {useState} from "react";
import Dropdown from './dropdown';

const MenuItem = ({ item, anchorClass, scrollTo, index }) => {
    const [dropdown, setDropdown] = useState(false);
    const toggleDropdown = () => {
        setDropdown(!dropdown)
    }

    return (

        <li className="menu-item" onClick={toggleDropdown}>
            {item.submenu ? (
                <button type="button" aria-haspopup="menu" className={"menu-item"}>
                    <a className={anchorClass}>{item.title}</a>
                    <Dropdown submenus={item.submenu} anchorClass={anchorClass} open={dropdown}/>
                </button>
            ) : item.url? (
                <div className={"menu-item"} key={index}>
                    <a className={anchorClass}
                       href={item.url}>{item.title}</a>
                </div>
            ) : (
                <div className={"menu-item"} key={index}>
                    <a onClick={() => scrollTo(item.scroll)}
                       className={anchorClass}>{item.title}</a>
                </div>
            )}
        </li>

    );
};

export default MenuItem;