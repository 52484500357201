import React from 'react';

const Services = (props) => {
    return (
        <div className={"container services" + (window.innerWidth < 1250 ? " bg-fixed" : "")} ref={props.servicesRef}>
            <div className={"bg-fixed"}/>
            <div className={"text-container"}>
                <span className={"bold medium"}>
                    Zajmuję się diagnostyką i leczeniem psychofarmakologicznym zaburzeń i chorób
                    psychicznych z
                    zakresu:
                </span>
                <ul className={"list"}>
                    <li className={"item regular medium"}>Zaburzeń adaptacyjnych i reakcji na ciężki stres</li>
                    <li className={"item regular medium"}>Zaburzeń lękowych (fobie, lęk paniczny, zaburzenie lękowe uogólnione)</li>
                    <li className={"item regular medium"}>Zaburzeń nastroju (depresja, choroba afektywna dwubiegunowa)</li>
                    <li className={"item regular medium"}>Schizofrenii</li>
                    <li className={"item regular medium"}>Zaburzeń schizoafektywnych</li>
                    <li className={"item regular medium"}>Zaburzeń urojeniowych</li>
                    <li className={"item regular medium"}>Zaburzeń psychotycznych</li>
                    <li className={"item regular medium"}>Otępień</li>
                </ul>
            </div>
        </div>
    );
}

export default Services;
