import React from "react";
import {ArrowNarrowLeft} from "tabler-icons-react";

const About = () => {
    return (
        <div className={"container main-about"}>
            <a className={"back"} href={"/"}>
                <ArrowNarrowLeft size={50}/>
            </a>
            <div className={"text-container"}>
                <span className={"bold big"}>O mnie</span>
                <span className={"regular medium"}>
                    Pierwsze doświadczenia w psychiatrii zdobyłam jeszcze przed rozpoczęciem studiów, uczestnicząc w
                    trzytygodniowym stażu
                    w Szpitalu Uniwersyteckim Alabamy w Stanach Zjednoczonych. To wtedy obudziła się we mnie chęć
                    zgłębienia tematu kryzysów psychicznych i pomocy osobom ich doświadczającym. W trakcie studiów moje
                    zainteresowanie psychiatrą rosło i dodawało mi energii, by angażować się zarówno naukowo, jak
                    również społecznie. W tym okresie należałam do licznych kół naukowych, skupiających się m.in. na
                    psychiatrii ambulatoryjnej i klinicznej, medycynie stylu życia czy medycynie rodzinnej. Podjęłam
                    pierwsze kroki w zakresie działalności naukowej, która zaowocowała publikacjami i wystąpieniami na
                    konferencjach zarówno w kraju, jak i za granicą. Byłam wolontariuszką w Oddziale Zaburzeń
                    Afektywnych Instytutu Psychiatrii i Neurologii oraz w Fundacji SASA (fundacja studencka działająca
                    na rzecz poprawy dostępności podstawowego sprzętu medycznego i badań profilaktycznych dla
                    pacjentów).
                </span>
                <span className={"regular medium"}>
                    Po ukończeniu studiów w 2020 roku podjęłam pracę jako lekarz rezydent na Oddziale Psychiatrycznym w
                    Szpitalu Bielańskim. Do tej pory odbyłam staże w Oddziale Całodobowym, na Oddziałach Dziennych
                    (zarówno
                    rehabilitacyjnym, jak również terapeutycznym), pracowałam w Poradni Zdrowia Psychicznego i w ramach
                    Zespołu Leczenia Środowiskowego. Kontynuuję również moją działalność naukową.
                    W 2021 roku, chcąc pogłębić moją wiedzę i umiejętności pomocy osobom w kryzysie psychicznym,
                    rozpoczęłam
                    studia w Szkole Psychoterapii w Ośrodku INTRA.
                </span>
                <span className={"regular medium"}>
                    Na co dzień jestem żoną, przyjaciółką, córką, siostrą i wnuczką. Miłośniczką podróży bliskich i
                    dalekich. Jeżeli tylko mogę, przemieszczam się na rowerze. By złapać oddech, praktykuję jogę.
                </span>
            </div>
            <img className={"image"} src={process.env.PUBLIC_URL + "/img/img3.jpg"} alt={"img4"}/>
        </div>
    )
}

export default About;