import React from 'react';

const Contact = () => {
    return (

        <div className={"container"}>
            <div className={"bg-white"}>
                <div className={"bg-orange"}>
                    <div className={"text-container white"}>
                        <span className={"bold medium"}>Gabinet Zuzanna Goetz-Kundera</span>
                        <div className={"number"}>
                            <span className={"regular medium"}>Tel.&nbsp;</span>
                            <span className={"bold medium"}>789 236 385</span>
                        </div>
                        <div>
                            <span className={"regular medium"}>Dostępność telefoniczna:</span>
                            <div>
                                <span className={"regular medium"}>poniedziałki godz. </span>
                                <span className={"bold medium"}>16-21</span>
                            </div>
                            <div>
                                <span className={"regular medium"}>wtorki godz. </span>
                                <span className={"bold medium"}>16-21</span>
                            </div>
                            <div>
                                <span className={"regular medium"}>piątki godz. </span>
                                <span className={"bold medium"}>16-21</span>
                            </div>
                            <span
                                className={"regular medium"}>W pozostałe dni proszę o kontakt SMS-owy lub mailowy.</span>
                        </div>
                        <span className={"bold medium"}>gabinetzuzannagoetzkundera@gmail.com</span>
                        <div className={"number"}>
                            <span className={"regular medium"}>NIP.&nbsp;</span>
                            <span className={"bold medium"}>113 307 21 05</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"pricing-tile"}>
                <a className={"bold medium underline black"} href={"/cennik"}>cennik</a>
            </div>
        </div>

    );
}

export default Contact;
