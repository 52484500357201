import React from 'react';
import {ArrowNarrowDown} from "tabler-icons-react";

const Hero = ({scrollTo, aboutRef}) => {
    return (
        <div className={"hero"}>
            <ArrowNarrowDown size={50} className={"arrow down"} alt={"arrow-down"} onClick={() => scrollTo(aboutRef)}/>
        </div>
    );
}

export default Hero;