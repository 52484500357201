import React from 'react';
import useElementOnScreen from "../../utilities/useElementOnScreen";

const About = () => {

    const [isVisible, currentRef] = useElementOnScreen({root: null, rootMargin: "0px", threshold: 1});

    return (

        <div className={"container about"}>
            <div className={"text-container"}>
                <span className={"bold big"}>O mnie</span>
                <span className={"regular medium"}>
                    Pierwsze doświadczenia w psychiatrii zdobyłam jeszcze przed rozpoczęciem
                    studiów, uczestnicząc w trzytygodniowym stażu w Szpitalu Uniwersyteckim Alabamy w Stanach
                    Zjednoczonych. To wtedy obudziła się we mnie chęć zgłębienia tematu kryzysów psychicznych i pomocy
                    osobom ich doświadczającym.&nbsp;
                    <span className={"regular medium narrow"}>
                    W trakcie studiów moje zainteresowanie psychiatrą rosło i dodawało mi
                    energii, by angażować się zarówno naukowo, jak również społecznie. W tym okresie należałam do
                    licznych kół naukowych, skupiających się m.in. na psychiatrii ambulatoryjnej i klinicznej, medycynie
                    stylu życia czy medycynie rodzinnej.
                </span>
                </span>
                <a className={"bold medium underline black"} href={"/o-mnie"}>czytaj więcej</a>
            </div>
            <div className={"end"}>
                <div className={"image-container" + (isVisible ? " active" : "")}>
                    <img className={"image" + (isVisible ? " active" : "")}
                         src={process.env.PUBLIC_URL + "/img/img4.png"} alt={"zuzanna"}/>
                </div>
            </div>
            <div className={"refBox"} ref={currentRef}/>
        </div>

    );
}

export default About;
