import React, {useRef} from 'react';
import Menu from "./menu";
import "../../index.css"

const Header = ( {scrollTo, contactRef, servicesRef} ) => {

    return (

        <header className={"header"}>
            <a className={"big bold black no-underline"} href={"/"}>Zuzanna Goetz-Kundera</a>
            <Menu scrollTo={scrollTo} servicesRef={servicesRef} contactRef={contactRef} footer={false}/>
        </header>

    );
}

export default Header;
